<template>
  <b-container class="pt-4 mt-5 mb-5 form-login">
    <b-row>
      <b-col>
        <h3>Iniciar Sesión</h3>
      </b-col>
    </b-row>
    <b-row class="mt-4">
      <b-col cols="10" offset="1" class="text-center">
        <b-input-group class="mb-2">
          <b-input-group-prepend is-text>
            <b-icon icon="at"></b-icon>
          </b-input-group-prepend>
          <b-form-input
            v-model="userEmail"
            type="text"
            placeholder="Email"
          ></b-form-input>
        </b-input-group>
        <b-input-group class="mt-4">
          <b-input-group-prepend is-text>
            <b-icon icon="lock-fill"></b-icon>
          </b-input-group-prepend>
          <b-form-input
            v-model="userPassword"
            type="password"
            placeholder="Password"
          ></b-form-input>
        </b-input-group>

        <b-button class="mt-5 mb-5" variant="primary" @click="login"
          >Ingresar</b-button
        >
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
  data() {
    return {
      userEmail: "",
      userPassword: "",
    };
  },
  computed: {},
  mounted() {
    this.setLayout("AdminLayout");
  },
  methods: {
    ...mapActions(["setLayout", "signIn", "registerUser"]),
    register() {
      this.registerUser({
        email: this.userEmail,
        password: this.userPassword,
      })
        .then((rta) => {
          this.$router.replace("/admin");
        })
        .catch((err) => {
          if (err.code == "auth/argument-error") {
            this.$toastr.e("Los datos no son validos.");
          }
          console.log("error iniciando sesion", err);
        });
    },
    login() {
      this.signIn({
        email: this.userEmail,
        password: this.userPassword,
      })
        .then((rta) => {
          this.$router.replace("/admin");
        })
        .catch((err) => {
          if (err.code == "auth/argument-error") {
            this.$toastr.e("Los datos no son validos.");
          }
          if (err.code == "auth/wrong-password") {
            this.$toastr.e("La contraseña es inválida o el usuario no existe.");
          }
          if (err.code == "auth/user-not-found") {
            this.$toastr.e("La contraseña es inválida o el usuario no existe.");
          }
          if (err.code == "auth/invalid-email") {
            this.$toastr.e("Dirección de email no válida.");
          }
          // console.log("error iniciando sesion", err);
        });
    },
  },
};
</script>

<style lang="scss">
.form-login {
  width: 60%;
  border: 1px solid #bfbfbf;
  background-color: #cacaca56;
  box-shadow: 1px 1px 10px 5px #999999;
}

.form-login h3 {
  color: #0a2799;
  font-weight: bolder;
}
</style>